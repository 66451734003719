import 'react-perfect-scrollbar/dist/css/styles.css';
import {useRoutes} from 'react-router-dom';
import './App.css';
import theme from './theme';
import routes from './routes';
import {ThemeProvider} from "@mui/material";
import {AuthProvider} from "./contexts/AuthContext";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {MessageProvider} from "./contexts/MessageContext";
import {SearchProvider} from "./contexts/SearchContext";

const App = () => {
  const content = useRoutes(routes);

  return (
        <ThemeProvider theme={theme}>
          <MessageProvider>
            <SearchProvider>
            <AuthProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  {content}
              </LocalizationProvider>
            </AuthProvider>
            </SearchProvider>
            </MessageProvider>
        </ThemeProvider>
  );
};

export default App;
