import httpService from "./HttpService";

export const getWeek = async (weekId) => {
  const token = localStorage.getItem("auth");
  return httpService.get(`admin/program/week/${weekId}`, null, token);
};

export const getWorkout = async (workoutId) => {
  const token = localStorage.getItem("auth");
  return httpService.get(`admin/program/workout/${workoutId}`, null, token);
};

export const getCurrentPlan = async (traineeId) => {
  const token = localStorage.getItem("auth");
  return httpService.get(`admin/program/${traineeId}`, null, token);
};

export const getPlan = async (traineeId, programId) => {
  const token = localStorage.getItem("auth");
  return httpService.get(`admin/program/${traineeId}/${programId}`, null, token);
};

export const addNewBlock = async (programId) => {
  const token = localStorage.getItem("auth");
  return httpService.get(`admin/program/create_block/${programId}`, null, token);
};

export const getHistory = async (traineeId) => {
  const token = localStorage.getItem("auth");
  return httpService.get(`admin/program/history/${traineeId}`, null, token);
}

export const changeProgram = async (traineeId, templateId) => {
  const token = localStorage.getItem("auth");
  return httpService.post(`admin/program/change/${traineeId}/${templateId}`, null, token);
}

export const updateProgram = async (body) => {
  const token = localStorage.getItem("auth");
  return httpService.post(`admin/program/updateWorkout`, body, token);
}

export const getPendingPrograms = async (companyId, keyword, page= 0, trainer) => {
  const token = localStorage.getItem("auth");

  let url = `admin/program/pending`;
  url += `?page=${page}`

  if (companyId != null){
    url += `&companyId=${companyId}`
  }

  if (keyword !=null) {
    url += `&keyword=${keyword}`
  }

  if (trainer !== 0) {
    url += `&trainer=${trainer}`
  }

  return httpService.get(url, null, token);
}

export const approvePendingProgram = async (pendingId) => {
  const token = localStorage.getItem("auth");
  return httpService.post(`admin/program/pending/${pendingId}`, null, token);
}

export const finishWorkout = async (workoutId) => {
  const token = localStorage.getItem("auth");
  return httpService.put(`admin/program/finish/${workoutId}`, null, token);
}

export const resetWeek = async (weekId) => {
  const token = localStorage.getItem("auth");
  return httpService.delete(`admin/program/reset/${weekId}`, null, token);
}

export const rerunManipulation = async (traineeId, programId, weekId) => {
  const token = localStorage.getItem("auth");
  return httpService.get(`admin/program/manipulation/${traineeId}/${programId}/${weekId}`, null, token);
}
