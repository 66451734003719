import PageHeader from "../componenets/shared/PageHeader";
import {useState} from "react";
import {getTemplates, saveMetaData} from "../services/TemplatesService";
import MiniTemplates from "../componenets/templates/MiniTemplates";
import {Box, Button} from "@mui/material";
import {ReactComponent as PlusIcon} from "../assets/images/plus.svg";
import TemplateSearchBar from "../componenets/TemplateSearchBar";
import TemplateEditMetaData from "../componenets/templates/TemplateEditMetaData";
import {useNavigate} from "react-router-dom";
import {useMessageContext} from "../contexts/MessageContext";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useSearchContext} from "../contexts/SearchContext";

const Templates = () => {
  const [newTemplate, setNewTemplate] = useState(false);
  let navigate = useNavigate();
  const {notificationHandler} = useMessageContext();
  const {templateSearchParams, setTemplateSearchParams} = useSearchContext();

  console.log("templateSearchParams", templateSearchParams)
  const handleNewTemplate = (templateMetaData) => {
    saveMetaData(templateMetaData)
        .then(res => navigate(`/app/templates/${res.data.id}`))
            .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
  }

  const updateNumberOfWorkouts = (numberOfWorkouts) => {
    setTemplateSearchParams((prv) => {
      return {
        ...prv,
        totalNumberOfElements: 0,
        numberOfWorkouts: numberOfWorkouts,
        pageId: 0,
        templates: [],
        hasMoreData: true,
        isLoading: true,
      };
    });
  };

  const updateGender = (gender) => {
    setTemplateSearchParams((prv) => {
      return {
        ...prv,
        totalNumberOfElements: 0,
        gender: gender,
        pageId: 0,
        templates: [],
        hasMoreData: true,
        isLoading: true,
      };
    });
  };

  const updateMainMuscle = (mainMuscle) => {
    setTemplateSearchParams((prv) => {
      return {
        ...prv,
        totalNumberOfElements: 0,
        mainMuscle: mainMuscle,
        pageId: 0,
        templates: [],
        hasMoreData: true,
        isLoading: true,
      };
    });
  };

  const updateSecondaryMuscle = (secondaryMuscle) => {
    setTemplateSearchParams((prv) => {
      return {
        ...prv,
        totalNumberOfElements: 0,
        secondaryMuscle: secondaryMuscle,
        pageId: 0,
        templates: [],
        hasMoreData: true,
        isLoading: true,
      };
    });
  };

  const updateExperienceLevel = (experienceLevel) => {
    setTemplateSearchParams((prv) => {
      return {
        ...prv,
        totalNumberOfElements: 0,
        experienceLevel: experienceLevel,
        pageId: 0,
        templates: [],
        hasMoreData: true,
        isLoading: true,
      };
    });
  };

  const updateKeyword = (keyword) => {
    setTemplateSearchParams((prv) => {
      return {
        ...prv,
        totalNumberOfElements: 0,
        keyword: keyword,
        pageId: 0,
        templates: [],
        hasMoreData: true,
        isLoading: true,
      };
    });
  };

  const fetchNextPage = () => {
    getTemplates(
        templateSearchParams.pageId,
        20,
        templateSearchParams.numberOfWorkouts,
        templateSearchParams.experienceLevel,
        templateSearchParams.keyword,
        templateSearchParams.mainMuscle,
        null,
        null,
        templateSearchParams.secondaryMuscle,
        templateSearchParams.secondaryMuscle2,
        templateSearchParams.gender
    )
        .then((res) => {
          const content = res.data.content;

          setTemplateSearchParams((prevState) => {
            let newTemplates = [...prevState.templates, ...content];

            if (newTemplates.pageId === 0) {
              newTemplates = content;
            }

            return {
              ...prevState,
              templates: newTemplates,
              numberOfElements: res.data.numberOfElements,
              pageId: prevState.pageId + 1,
              hasMoreData: content.length !== 0,
              isLoading: false,
            };
          });
        })
        .catch((e) => {
          setTemplateSearchParams((prvState) => {
            return { ...prvState, isLoading: false, hasMoreData: false };
          });
          console.log("Error: ", e);
        });
  };

  return (
      <>
        {templateSearchParams &&
            <>
        <PageHeader title={"תוכניות אימון"} >
          <Box sx={{marginTop: {xs: "0", md: "28px"}, display: "flex", justifyContent: {md: "flex-end", xs: "start"}, alignItems: "center"}}>
            <Box sx={{display: {xs : "none", md: "inherit"}}}>
              <Button variant="outlined"
                      onClick={() => setNewTemplate(true)}
                      sx={{backgroundColor: "#F2F4F7", height: "44px", width: "166px", "& .MuiButton-startIcon" : {
                          marginRight: "-4px",
                          marginTop: "2px",
                          marginLeft: "8px",
                        }}} startIcon={<PlusIcon/>}>
                תוכנית חדשה
              </Button>
            </Box>
          </Box>
        </PageHeader>
        <TemplateEditMetaData
            handleSubmit={handleNewTemplate}
            close={() => setNewTemplate(false)}
            open={newTemplate}/>
        <Box sx={{margin: {md: "12px", xs: "0"}}}>
          <TemplateSearchBar
              numberOfWorkouts={templateSearchParams.numberOfWorkouts}
              updateNumberOfWorkouts={updateNumberOfWorkouts}
              experienceLevel={templateSearchParams.experienceLevel}
              keyword={templateSearchParams.keyword}
              updateKeyword={updateKeyword}
              updateExperienceLevel={updateExperienceLevel}
              updateSecondaryMuscle={updateSecondaryMuscle}
              secondaryMuscle={templateSearchParams.secondaryMuscle}
              updateGender={updateGender}
              gender={templateSearchParams.gender}
              mainMuscle={templateSearchParams.mainMuscle}
              updateMainMuscle={updateMainMuscle}/>
          <MiniTemplates templatesState={templateSearchParams} fetchNextPage={fetchNextPage}/>
        </Box>
            </>}
      </>
  );
};

export default Templates;
