import React, { useContext, useEffect, useState } from "react";

// Create the Search Context
const SearchContext = React.createContext({
  templateSearchParams: {},
  setTemplateSearchParams: () => {},
});

// Custom hook to access the Search Context
export function useSearchContext() {
  return useContext(SearchContext);
}

// Provider for the Search Context
export function SearchProvider({ children }) {
  // Correctly initialize state with useState
  const [templateSearchParams, setTemplateSearchParams] = useState({
    templates: [],
    experienceLevel: "ALL",
    mainMuscle: "null",
    secondaryMuscle: "null",
    gender: "null",
    numberOfElements: 0,
    numberOfWorkouts: 0,
    keyword: "",
    pageId: 0,
    hasMoreData: true,
    isLoading: true,
  });

  // Value to be provided to the context
  const value = {
    templateSearchParams,
    setTemplateSearchParams,
  };

  return (
      <SearchContext.Provider value={value}>
        {children}
      </SearchContext.Provider>
  );
}
